import CompletedIcon from '../../../assets/check-circle.svg';
import FailedIcon from '../../../assets/failed.svg';
import QueuedIcon from '../../../assets/queuedIcon.svg';
import RunningIcon from '../../../assets/runningIcon.svg';
import CompletedNoDataIcon from '../../../assets/noDataIcon.svg';
import CancelledIcon from '../../../assets/cancelledIcon.svg';

export const getType = (cm, type) => {
    switch (type) {
        case 'limited':
            return cm.get('Acquisition');
        case 'standard':
            return cm.get('ReportTypeStandard');
        case 'extended':
            return cm.get('ReportTypeDetailed');
        case 'affiliate_fraud':
            return cm.get('AffiliateFraud');
        case 'user_validation':
            return cm.get('UserValidation');
        case 'comprehensive_detection_report':
            return cm.get('ExtendedResponseRTI');
        case 'extended_response_client_report':
            return cm.get('ExtendedResponseClientReport');
        default:
            return cm.get('InvalidAffiliateVisits');
    }
};

export const getFrequencyText = (cm, frequency) => {
    switch (frequency) {
        case 'onetime':
            return cm.get('Frequency_Onetime');
        case 'daily':
            return cm.get('Frequency_Daily');
        case 'monthly':
            return cm.get('Frequency_Monthly');
        case 'hourly':
            return cm.get('Frequency_Hourly');
        default:
            return cm.get('Frequency_Weekly');
    }
};

export const getStatusIcon = status => {
    switch (status) {
        case 'Completed':
            return CompletedIcon;
        case 'Failed':
            return FailedIcon;
        case 'Pending':
        case 'Queued':
            return QueuedIcon;
        case 'CompletedNoData':
            return CompletedNoDataIcon;
        case 'Running':
            return RunningIcon;
        case 'Cancelled':
            return CancelledIcon;
    }
};
