import React from 'react';
import moment from"moment";
import Download from '../../../../assets/download.svg';
import Remove from '../../../../assets/delete.svg';
import styles from '../../table.module.scss';
import { getType, getFrequencyText, getStatusIcon } from "../utils";
import mixpanel from "@services/mixpanel";
import { useSplit } from '../../../../../../../hooks/use-split';
import { constants as splitConstants } from '../../../../../../../utils/split.io';

const oneTimeReport = 'onetime';

const Row = ({ cm, row, reportMethods }) => {
    const { onDelete } = reportMethods;
    const { isOn: showScheduledReportsStatusFromAirflow } = useSplit(splitConstants.PARADOME_SCHEDULED_REPORTS_STATUS);
    const effectiveStatus = showScheduledReportsStatusFromAirflow ? row?.status : row?.statusSkewed;
    const effectiveCreatedAt = !showScheduledReportsStatusFromAirflow && row.frequency !== oneTimeReport ? row?.createdAtTasks : row?.createdAt;
    const isOnDemandReport = (frequency) => {
        return frequency === oneTimeReport
    }

    const showDeleteIcon = (status) => {
        return status !== 'Running' && status !== 'Cancelled';
    }

    const sendEventToMixpanel = (row) => {
        mixpanel.postEvent("Exportable report download", {
            "Exportable report create date": row.createdAt,
            "Exportable report type": row.type,
            "Exportable report frequency": row.frequency
        })
    }

    return (
        <div className={styles.row} data-test-selector="exportables-table-reports-row">
            <div className={styles.item}>{row.name}</div>
            <div className={styles.item}>{getType(cm, row.type)}</div>
            <div className={styles.item}>{moment(effectiveCreatedAt).format('DD MMMM YYYY, hh:mm A')}</div>
            <div className={styles.item}>{effectiveCreatedAt}</div>
            <div className={styles.item}>{getFrequencyText(cm, row.frequency)}</div>
            <div className={`${styles.item} ${styles.status} ${styles[effectiveStatus]} `}>
                <img className={styles.icon} src={getStatusIcon(effectiveStatus)} alt="status-icon"/>
                {effectiveStatus === 'Pending' ? 'Queued' : cm.get(effectiveStatus)}
            </div>
            <div className={styles.item}>
                {isOnDemandReport(row.frequency) && (
                    <div className={styles.icons}>
                        {effectiveStatus === 'Completed' && (
                            <a href={row.downloadUrl} target="_blank" rel="noreferrer" style={{display:"flex", alignItems:"center"}} onClick={() => sendEventToMixpanel(row)}>
                                <img className={styles.download} src={Download} alt="download"/>
                            </a>
                        )}
                        {showDeleteIcon(effectiveStatus) && <img className={styles.download} src={Remove} alt="Remove" onClick={() => onDelete(row.id,row.name)}/> }
                    </div>
                )}
            </div>
        </div>
    );
};

export default Row;